import React from "react";
import AwardWinningHeader from "../../../components/AwardWinningHeader";
import FooterImgSection from "../../../components/FooterImgSection";

function AwardWinning() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/7.WebP"
          alt="Award-Winning"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <AwardWinningHeader />
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default AwardWinning;
