import React from "react";
import LeverageSalesheader from "../../../components/LeverageSalesheader";
import FooterImgSection from "../../../components/FooterImgSection";

function LeverageSalesAnalysis() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/1.WebP"
          alt="Sales-Analysis"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <LeverageSalesheader />
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default LeverageSalesAnalysis;
