import React from "react";
import SalesandStocksAnalysisHeader from "../../../components/SalesandStocksAnalysisHeader";
import FooterImgSection from "../../../components/FooterImgSection";

function SalesStockAnalysis() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/5.WebP"
          alt="Stock-Analysis"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <SalesandStocksAnalysisHeader />
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default SalesStockAnalysis;
