import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./pages/About";
import Resource from "./pages/Resource";
import CompetitorAnalysis from "./pages/Features/CompetitorAnalysis";
import SalesAnalysis from "./pages/Features/SalesAnalysis";
import ContactUs from "./pages/ContactUs";
import GotoTopButton from "./components/GotoTopButton";
import UnveilingCustomer from "./pages/Blogs/UnveilingCustomer";
import LeverageSalesAnalysis from "./pages/Blogs/LeverageSalesAnalysis";
import DynamicRetailPrice from "./pages/Blogs/DynamicRetailPrice";
import SalesStockAnalysis from "./pages/Blogs/SalesStockAnalysis";
import AwardWinning from "./pages/Blogs/AwardWinning";
import ScrollToTop from "./components/ScrollToTop";
import Error from "./pages/Error";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PriceTracking from "./pages/PriceTracking";

function App() {
  return (
    <div>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/resources" element={<Resource />} />

        <Route path="/unveiling-customer-insights-market-basket-analysis" element={<UnveilingCustomer />} />
        <Route path="/leveraging-sales-analysis-and-competitor-price-index-for-business-growth" element={<LeverageSalesAnalysis />} />
        <Route path="/dynamics-of-retail-price-index-and-consumer-price" element={<DynamicRetailPrice />} />
        <Route path="/sales-and-stocks-analysis" element={<SalesStockAnalysis />} />
        <Route path="/award-winning-company-orbio-solutions" element={<AwardWinning />} />

        <Route path="/competitor-analysis" element={<CompetitorAnalysis />} />
        <Route path="/sales-analysis" element={<SalesAnalysis />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/price-tracking" element={<PriceTracking />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
      <GotoTopButton />
    </div>
  );
}

export default App;
