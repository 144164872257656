import React from "react";
import UnveilingCustomerHeader from "../../../components/unveilingCustomerHeader/UnveilingCustomerHeader";
import FooterImgSection from "../../../components/FooterImgSection";

function UnveilingCustomer() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/2.WebP"
          alt="Customer"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <UnveilingCustomerHeader />
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default UnveilingCustomer;
