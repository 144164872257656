import React from "react";
import DynamicRetailPriceHeader from "../../../components/DynamicRetailPriceHeader";
import FooterImgSection from "../../../components/FooterImgSection";

function DynamicRetailPrice() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/6.WebP"
          alt="Retail-Price"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <DynamicRetailPriceHeader />
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default DynamicRetailPrice;
