import React, { useState } from "react";
import styles from "./pricetracking.module.css";
import { Helmet } from "react-helmet";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { GoDash } from "react-icons/go";
import {
  FaChartLine,
  FaRegListAlt,
  FaHistory,
  FaTag,
  FaBolt,
  FaLightbulb,
  FaBell,
  FaExclamationTriangle,
} from "react-icons/fa";
import ScheduleDemoArrow from "../../components/ScheduleDemoArrow";
import FooterImgSection from "../../components/FooterImgSection";

const PriceTracking = () => {
  const [isRetailers, setIsRetailers] = useState(true);

  const handleToggle = () => {
    setIsRetailers(!isRetailers);
  };

  let paraStyle = { color: "#7a7e83", wordBreak: "break-word" };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://ottixhow.com/price-tracking" />
        <title>
          Optimize Pricing with Competitor Price Tracking Software
        </title>
        <meta
          name="description"
          content="Elevate your pricing strategy with OttixHow's competitor price tracking software. Get real-time insights and stay ahead in the market. Try our demo today!"
        />
        <meta
          property="og:title"
          content="Optimize Your Strategy with Our Competitor Price Monitoring Software"
        />
        <meta
          property="og:description"
          content="Unlock the potential of OttixHow's top-tier competitor price monitoring software. Track real-time pricing, analyze trends, and outshine your competition."
        />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "OttixHow",
            "description": "Elevate your pricing strategy with OttixHow's competitor price tracking software. Get real-time insights and stay ahead in the market. Try our demo today!",
            "brand": {
              "@type": "Brand",
              "name": "OttixHow"
            },
            "offers": {
              "@type": "Service",
              "url": "https://www.ottixhow.com/price-tracking"
            }
          }
        `}
        </script>
      </Helmet>
      <div className={styles.divContainer}>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Price Tracking</h1>
          <h2>
            Competitor Price Tracking Software: Gain the Upper Hand in Your
            Market
          </h2>
        </div>
      </div>

      <div className="mt-4">
        <Container>
          <h2 className="text-center fw-bold">
            Is Your Pricing Strategy Ready to Wow Your Customers? Try Our
            Competitor Price Tracking Software and Lead the Market!
          </h2>
          <p style={paraStyle}>
            If you aim to attract and satisfy more customers with smart pricing
            strategies, making competitor price tracking your priority is
            essential. By leveraging our{" "}
            <span className="fw-bold">competitor price tracking software</span>,
            you can ensure that your prices meet market expectations and give
            you an edge over your competitors. Let us help you win more
            customers by optimizing your pricing strategy for maximum value. In
            the fast-paced world of e-commerce, staying ahead means knowing
            exactly what your competitors are up to. With OttixHow's{" "}
            <span className="fw-bold">Competitor Price Tracking Software</span>,
            you can effortlessly keep tabs on market trends, and competitor
            pricing, and make strategic decisions that position you for success.
            It's time to transform your pricing strategy and outsmart the
            competition.
          </p>
          <div
            style={{
              backgroundColor: "#f9f9f9",
              padding: "2rem",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              border: "1px solid #e0f2f1",
            }}
          >
            <h2
              className="fw-bold fs-4"
              style={{
                color: "black",
                marginBottom: "1rem",
              }}
            >
              What Sets OttixHow’s Competitor Price Tracking Apart?
            </h2>
            <p
              style={{
                fontSize: "1rem",
                color: "#555",
                lineHeight: "1.6",
              }}
            >
              OttixHow isn't just another tool; it's your secret weapon in the
              battle for market dominance. Here's what sets us apart: Whether
              you are an e-commerce store owner, a brand manager, or a pricing
              analyst, understanding the pricing dynamics in your industry can
              be a game-changer. With real-time data and actionable insights,
              our software empowers you to make informed pricing decisions that
              drive sales and maximize profits.
            </p>
          </div>
        </Container>
      </div>

      <div style={{ backgroundColor: "#02183d" }} className="pt-4 pb-4 mt-4">
        <Container className="p-3 mt-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6} className="d-flex flex-column">
              <h5
                style={{ letterSpacing: "2px", color: "whitesmoke" }}
                className="d-flex align-items-center"
              >
                <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                PRICE TRACKING
              </h5>
              <h3 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                Get an Overview of Your Competitors and Business
              </h3>
              <p style={{ ...paraStyle, marginBottom: "0px" }}>
                Understanding your competition is vital for crafting a
                successful pricing strategy. Our{" "}
                <span className="fw-bold">
                  competitor price tracking software
                </span>{" "}
                provides you with a comprehensive overview of your market
                landscape. Here's what you can achieve:
              </p>
              <ul style={{ ...paraStyle }}>
                <li>
                  <span className="fw-bold">Identify Competitors:</span>{" "}
                  Discover who your direct competitors are and how their pricing
                  strategies compare to yours.
                </li>
                <li>
                  <span className="fw-bold">Analyze Pricing Trends:</span> Track
                  pricing trends over time to identify patterns and
                  opportunities.
                </li>
                <li>
                  <span className="fw-bold">Monitor Promotions:</span> Stay
                  informed about competitors' promotional activities and adjust
                  your strategies accordingly.
                </li>
              </ul>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src="assets/competitors-graph.WebP"
                alt="competitors-graph"
                fluid
                style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="p-3 mt-5">
          <Row className="d-flex align-items-center">
            <Col md={6} className="order-2 order-md-1">
              <div>
                <Image
                  src="assets/reports-notifications.WebP"
                  alt="historicalprice"
                  fluid
                />
              </div>
            </Col>
            <Col md={6} className="order-1 order-md-2">
              <div>
                <h5
                  style={{ letterSpacing: "2px" }}
                  className="d-flex align-items-center"
                >
                  <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                  PRICE TRACKING
                </h5>
                <h3 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                  Receive Personalized Reports and Notifications
                </h3>
                <p style={paraStyle}>
                  With OttixHow, you never have to worry about missing crucial
                  market shifts. Our solution offers:
                </p>
                <h6 className="fw-medium mt-2 mb-2">Customizable Reports:</h6>
                <ul style={{ ...paraStyle }}>
                  <li>
                    <span className="fw-bold">Customizable Content:</span>{" "}
                    Tailor reports to your specific needs, focusing on the
                    metrics that matter most to your business.
                  </li>
                  <li>
                    <span className="fw-bold">Instant Alerts:</span> Receive
                    notifications whenever a competitor changes their prices, so
                    you can react swiftly.
                  </li>
                  <li>
                    <span className="fw-bold">Historical Data Analysis:</span>{" "}
                    Access past pricing data to understand trends and predict
                    future shifts.
                  </li>
                </ul>
              </div>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ backgroundColor: "#02183d" }} className="pt-4 pb-4">
        <Container className="p-3 mt-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6} className="d-flex flex-column">
              <h5
                style={{ letterSpacing: "2px", color: "whitesmoke" }}
                className="d-flex align-items-center"
              >
                <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                PRICE TRACKING
              </h5>
              <h3 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                Increase Your Sales in Just Five Minutes
              </h3>
              <p style={{ ...paraStyle, marginBottom: "0px" }}>
                Time is money, and with our{" "}
                <span className="fw-bold">price monitoring software</span> ,
                you'll save plenty of both. Our intuitive interface and powerful
                analytics tools allow you to:
              </p>
              <ul style={{ ...paraStyle }}>
                <li>
                  <span className="fw-bold">Optimize Pricing:</span> Use
                  insights from our software to set competitive prices that
                  attract customers and boost sales.
                </li>
                <li>
                  <span className="fw-bold">Maximize Margins:</span> Identify
                  opportunities to increase prices where your offering provides
                  unique value.
                </li>
                <li>
                  <span className="fw-bold">Boost Visibility:</span> Position
                  your products competitively in marketplaces to improve
                  visibility and drive traffic.
                </li>
              </ul>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src="assets/business-graph.WebP"
                alt="business-graph"
                fluid
                style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="p-3 mt-5">
          <Row className="d-flex align-items-center">
            <Col md={6} className="order-2 order-md-1">
              <div>
                <Image
                  src="assets/historicalprice.WebP"
                  alt="historicalprice"
                  fluid
                />
              </div>
            </Col>
            <Col md={6} className="order-1 order-md-2">
              <div>
                <h5
                  style={{ letterSpacing: "2px" }}
                  className="d-flex align-items-center"
                >
                  <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                  PRICE TRACKING
                </h5>
                <h3 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                  Competitor Price Monitoring: Automated and Accurate
                </h3>
                <p style={paraStyle}>
                  Manual price monitoring is time-consuming and prone to errors.
                  With OttixHow's{" "}
                  <sapn className="fw-bold">
                    competitor price monitoring software
                  </sapn>
                  , you benefit from:
                </p>
                <ul style={{ ...paraStyle }}>
                  <li>
                    <span className="fw-bold">Automation:</span> Automate the
                    tedious process of price monitoring and focus on strategic
                    decision-making.
                  </li>
                  <li>
                    <span className="fw-bold">Accuracy:</span> Get accurate,
                    real-time data that's updated frequently to ensure you're
                    always in the know.
                  </li>
                  <li>
                    <span className="fw-bold">Integration:</span> Seamlessly
                    integrate with your existing systems for hassle-free data
                    management.
                  </li>
                </ul>
              </div>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ backgroundColor: "#02183d" }} className="pt-4 pb-4">
        <Container className="p-3 mt-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6} className="d-flex flex-column">
              <h5
                style={{ letterSpacing: "2px", color: "whitesmoke" }}
                className="d-flex align-items-center"
              >
                <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                PRICE TRACKING
              </h5>
              <h3 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                See Where You Stand in the Market
              </h3>
              <p style={{ ...paraStyle, marginBottom: "0px" }}>
                Knowing your position in the market is key to maintaining a
                competitive edge. Our software provides:
              </p>
              <ul style={{ ...paraStyle }}>
                <li>
                  <span className="fw-bold">Market Positioning:</span> See where
                  your prices stand in comparison to competitors across various
                  platforms.
                </li>
                <li>
                  <span className="fw-bold">Competitive Insights:</span>{" "}
                  Understand which competitors offer the same products and at
                  what price.
                </li>
                <li>
                  <span className="fw-bold">Strategic Planning:</span> Use
                  insights to plan pricing strategies that enhance your market
                  position.
                </li>
              </ul>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src="assets/sales-graph.WebP"
                alt="sales-graph"
                fluid
                style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="p-3 mt-5">
          <Row className="d-flex align-items-center">
            <Col md={6} className="order-2 order-md-1">
              <div>
                <Image
                  src="assets/historicalprice.WebP"
                  alt="historicalprice"
                  fluid
                />
              </div>
            </Col>
            <Col md={6} className="order-1 order-md-2">
              <div>
                <h5
                  style={{ letterSpacing: "2px" }}
                  className="d-flex align-items-center"
                >
                  <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                  PRICE TRACKING
                </h5>
                <h4 className="mt-3 mb-3" style={{ color: "#faa61c" }}>
                  Take Action with Valuable Data
                </h4>
                <p style={paraStyle}>
                  Our <span className="fw-bold">price monitoring software</span>{" "}
                  doesn't just provide data; it empowers you to take action.
                  Here's how:
                </p>
                <ul style={{ ...paraStyle }}>
                  <li>
                    <span className="fw-bold">Marketing Opportunities:</span>{" "}
                    Identify products that can be highlighted in promotions for
                    maximum impact.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Strategic Pricing Adjustments:
                    </span>{" "}
                    Make informed decisions about when to lower or raise prices.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Enhanced Product Positioning:
                    </span>{" "}
                    Optimize your product assortment to align with market
                    demands.
                  </li>
                </ul>
              </div>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ backgroundColor: "#02183d" }} className="pt-4 pb-4">
        <Container className="p-3 mt-4">
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={6} className="d-flex flex-column">
              <h5
                style={{ letterSpacing: "2px", color: "whitesmoke" }}
                className="d-flex align-items-center"
              >
                <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                PRICE TRACKING
              </h5>
              <h4 className="mt-3 mb-3" style={{ color: "#faa61c" }}>
                E-Commerce Pricing Strategies Made Simple
              </h4>
              <p style={{ ...paraStyle, marginBottom: "0px" }}>
                Crafting a winning e-commerce pricing strategy has never been
                easier. With our software, you can:
              </p>
              <ul style={{ ...paraStyle }}>
                <li>
                  <span className="fw-bold">Understand Consumer Behavior:</span>{" "}
                  Analyze how consumers interact with your products and pricing.
                </li>
                <li>
                  <span className="fw-bold">Implement Dynamic Pricing:</span>{" "}
                  Use real-time data to implement dynamic pricing strategies
                  that boost profitability.
                </li>
                <li>
                  <span className="fw-bold">Stay Competitive:</span> Ensure your
                  prices remain competitive, attracting customers and driving
                  sales.
                </li>
              </ul>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src="assets/pricetracking.WebP"
                alt="pricetracking"
                fluid
                style={{ objectFit: "contain" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="p-3 mt-5">
          <Row className="d-flex align-items-center">
            <Col md={6} className="order-2 order-md-1">
              <div>
                <Image src="assets/buy-graph.WebP" alt="buy-box-graph" fluid />
              </div>
            </Col>
            <Col md={6} className="order-1 order-md-2">
              <div>
                <h5
                  style={{ letterSpacing: "2px" }}
                  className="d-flex align-items-center"
                >
                  <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
                  PRICE TRACKING
                </h5>
                <h2 className="mt-3 mb-3 fs-4" style={{ color: "#faa61c" }}>
                  Think Beyond the Buy Box
                </h2>
                <p style={paraStyle}>
                  The Amazon Buy Box is a critical element of product listings,
                  responsible for driving over 80% of Amazon's sales. This
                  feature highlights a single seller's offer based on a variety
                  of factors, including price, seller performance, and inventory
                  levels. However, focusing solely on the Buy Box can limit your
                  understanding of the broader pricing dynamics on Amazon.
                </p>
                <p style={paraStyle}>
                  At OttixHow, our Amazon price tracker offers a more
                  comprehensive approach. Instead of just monitoring Buy Box
                  listings, we track all offers across the platform. This
                  broader scope allows you to see a full pricing spectrum and
                  availability, giving you valuable insights into market trends
                  and competitive positioning. By capturing data from every
                  listing, not just those featured in the Buy Box, you gain a
                  complete view of price fluctuations and can identify patterns
                  that may not be evident from Buy Box data alone.
                </p>
                <p style={paraStyle}>
                  This extensive coverage helps you make well-informed
                  decisions, avoid potential pitfalls, and optimize your pricing
                  strategy. With OttixHow, you can navigate Amazon's complex
                  pricing landscape with confidence, ensuring you stay ahead of
                  the competition and make strategic moves based on the most
                  accurate and comprehensive data available.
                </p>
              </div>
              <div className="mt-2">
                <ScheduleDemoArrow clr="#faa71c" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <div
            style={{
              backgroundColor: "#2d313d",
              padding: "2rem",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              border: "1px solid #2d313d",
              color: "whitesmoke",
            }}
          >
            <h5
              className="fw-bold fs-4"
              style={{
                marginBottom: "1rem",
              }}
            >
              Join Us and Lead the Market!!!
            </h5>
            <p
              style={{
                fontSize: "1rem",
                lineHeight: "1.6",
              }}
            >
              Take the lead in e-commerce by choosing OttixHow's competitor
              price tracking software. We're here to help you stay ahead with
              our powerful tools that offer real-time data and actionable
              insights. Start monitoring prices with us today and revolutionize
              your pricing strategy to gain a competitive advantage. Don't just
              keep up with the market-lead it with OttixHow.
            </p>
          </div>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <div>
            <h5
              className="fw-bold fs-4"
              style={{
                color: "black",
                marginBottom: "1rem",
              }}
            >
              Who Can Benefit from OttixHow’s Competitor Price Tracking
              Software?
            </h5>
            <p
              style={{
                fontSize: "1rem",
                color: "black",
                lineHeight: "1.6",
              }}
            >
              Are you part of the eCommerce industry? Then{" "}
              <a href="/" target="_blank" rel="noreferrer">
                competitor price tracking software
              </a>{" "}
              is just what you need! Whether you’re a small business or a large
              retailer, your journey to success will be much smoother with the
              right price-tracking tools.
            </p>
          </div>
        </Container>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <input
          type="checkbox"
          id="toggle"
          className={"toggleCheckbox"}
          checked={!isRetailers}
          onChange={handleToggle}
        />
        <label htmlFor="toggle" className={"toggleContainer"}>
          <span style={{ letterSpacing: "3px" }}>FOR RETAILERS</span>
          <span style={{ letterSpacing: "3px" }}>FOR BRANDS</span>
        </label>
      </div>

      {isRetailers ? (
        <>
          <div className="mt-4">
            <Container>
              <div>
                <p
                  style={{
                    fontSize: "1rem",
                    lineHeight: "1.6",
                  }}
                >
                  For retailers, competitor price-tracking software is essential
                  for gaining better control over the market. In a competitive
                  environment, you must understand how your products stand
                  against competitors and how competitor actions impact your
                  pricing and sales growth.
                </p>
              </div>
            </Container>
          </div>

          <div className="mt-4">
            <Container>
              <h6 className="fs-4">
                OttixHow offers the best solutions for retailers, including:
              </h6>
              <Row className="mt-4">
                {[
                  {
                    icon: <FaChartLine size={40} />,
                    title: "Product Price Monitoring",
                    borderColor: "#0b76b4",
                    iconBgColor: "#0b76b4",
                    innerBorderColor: "#0b76b4",
                  },
                  {
                    icon: <FaRegListAlt size={40} />,
                    title: "Reporting Mechanisms",
                    borderColor: "#faa61c",
                    iconBgColor: "#faa61c",
                    innerBorderColor: "#faa61c",
                  },
                  {
                    icon: <FaHistory size={40} />,
                    title: "Clear Price History",
                    borderColor: "#0b76b4",
                    iconBgColor: "#0b76b4",
                    innerBorderColor: "#0b76b4",
                  },
                  {
                    icon: <FaTag size={40} />,
                    title: "Tracking Promotions & Stock",
                    borderColor: "#faa61c",
                    iconBgColor: "#faa61c",
                    innerBorderColor: "#faa61c",
                  },
                ].map((card, index) => (
                  <Col md={3} className="mb-3 d-flex" key={index}>
                    <Card
                      className="w-100 d-flex flex-column justify-content-between position-relative"
                      style={{
                        padding: "1rem",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        border: `1px solid ${card.borderColor}`,
                        textAlign: "center",
                      }}
                    >
                      <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                        <div
                          style={{
                            backgroundColor: card.iconBgColor,
                            borderRadius: "50%",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3)`,
                          }}
                        >
                          {card.icon}
                        </div>
                        <Card.Title
                          className="mt-3"
                          style={{ fontSize: "16px" }}
                        >
                          {card.title}
                        </Card.Title>
                      </Card.Body>
                      {/* Top-right border */}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "50%",
                          height: "50%",
                          borderTop: `5px solid ${card.innerBorderColor}`,
                          borderRight: `5px solid ${card.innerBorderColor}`,
                          borderTopRightRadius: "8px",
                          pointerEvents: "none",
                        }}
                      />
                      {/* Bottom-left border */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "50%",
                          height: "50%",
                          borderBottom: `5px solid ${card.innerBorderColor}`,
                          borderLeft: `5px solid ${card.innerBorderColor}`,
                          borderBottomLeftRadius: "8px",
                          pointerEvents: "none",
                        }}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <Container>
              <div
              // style={{
              //   backgroundColor: "#f9f9f9",
              //   padding: "2rem",
              //   borderRadius: "8px",
              //   boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              //   border: "1px solid #e0e0e0",
              // }}
              >
                {/* <h4
              className="fw-bold"
              style={{
                color: "black",
                marginBottom: "1rem",
              }}
            >
              Competitor Price Tracking for Brands
            </h4> */}
                <p
                  style={{
                    fontSize: "1rem",
                    color: "black",
                    lineHeight: "1.6",
                  }}
                >
                  As a brand, it’s crucial to know how your products are
                  positioned in the market. Do retailers adhere to agreed
                  prices? How are competitors responding to market changes?
                  Which competitors have the closest and farthest prices from
                  yours?
                </p>
              </div>
            </Container>
          </div>

          <div className="mt-4">
            <Container>
              <h6 className="fs-4" >OttixHow provides the answers with:</h6>
              <Row className="mt-4">
                {[
                  {
                    icon: <FaBolt size={40} />,
                    title: "Detection of any price change",
                    borderColor: "#0b76b4",
                    iconBgColor: "#0b76b4",
                    innerBorderColor: "#0b76b4",
                  },
                  {
                    icon: <FaLightbulb size={40} />,
                    title: "Price change suggestions",
                    borderColor: "#faa61c",
                    iconBgColor: "#faa61c",
                    innerBorderColor: "#faa61c",
                  },
                  {
                    icon: <FaBell size={40} />,
                    title: "Alerts and notifications system",
                    borderColor: "#0b76b4",
                    iconBgColor: "#0b76b4",
                    innerBorderColor: "#0b76b4",
                  },
                  {
                    icon: <FaExclamationTriangle size={40} />,
                    title: "Price violation detection (MAP monitoring)",
                    borderColor: "#faa61c",
                    iconBgColor: "#faa61c",
                    innerBorderColor: "#faa61c",
                  },
                ].map((card, index) => (
                  <Col md={3} className="mb-3 d-flex" key={index}>
                    <Card
                      className="w-100 d-flex flex-column justify-content-between position-relative"
                      style={{
                        padding: "1rem",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        border: `1px solid ${card.borderColor}`,
                        textAlign: "center",
                      }}
                    >
                      <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                        <div
                          style={{
                            backgroundColor: card.iconBgColor,
                            borderRadius: "50%",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: `0 4px 8px rgba(0, 0, 0, 0.3)`,
                          }}
                        >
                          {card.icon}
                        </div>
                        <Card.Title
                          className="mt-3"
                          style={{ fontSize: "16px" }}
                        >
                          {card.title}
                        </Card.Title>
                      </Card.Body>
                      {/* Top-right border */}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          width: "50%",
                          height: "50%",
                          borderTop: `5px solid ${card.innerBorderColor}`,
                          borderRight: `5px solid ${card.innerBorderColor}`,
                          borderTopRightRadius: "8px",
                          pointerEvents: "none",
                        }}
                      />
                      {/* Bottom-left border */}
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "50%",
                          height: "50%",
                          borderBottom: `5px solid ${card.innerBorderColor}`,
                          borderLeft: `5px solid ${card.innerBorderColor}`,
                          borderBottomLeftRadius: "8px",
                          pointerEvents: "none",
                        }}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </>
      )}

      <FooterImgSection
        mainTitle={"Retailer or Brand? Improve Your Pricing with One Click! "}
      />
    </>
  );
};

export default PriceTracking;
