import React, { useState } from "react";
import styles from "./about.module.css";
import { TiTick } from "react-icons/ti";
import FooterImgSection from "../../components/FooterImgSection";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import VideoModal from "../../components/VideoModal";
import AchievementSection from "../../components/AchievementSection";
import { FaPlay, FaRegEye } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";

function About() {
  const [videoModal, setVideoModal] = useState(false);

  const handleCloseModal = () => {
    setVideoModal(false);
  };

  const handleOpenVideoModal = () => {
    setVideoModal(true);
  };

  let mainTitle =
    "Enable Business Transformation with AI-Driven Retail Insights.";
  let subTitle =
    "Our advanced retail pricing intelligence and pricing analytics empower businesses to stay ahead in the competitive market landscape.";

  const helpBusiness = [
    {
      title: "Synergize Access to Technology:",
      description:
        "We implement strategic digital solutions to address business-centric challenges.",
    },
    {
      title: "Cultivate Reliable Solutions:",
      description:
        "We offer reliable solutions that foster enterprise growth across various verticals.",
    },
    {
      title: "Diversify Technology Use:",
      description:
        "We leverage technology to enhance business processes and efficiency.",
    },
    {
      title: "Develop Intuitive Products:",
      description:
        "We study customer and employee experiences to create user-friendly products.",
    },
    {
      title: "Bridge Legacy Gaps:",
      description:
        "We modernize legacy processes to improve business efficiency.",
    },
    {
      title: "Leverage Big Data & Analytics:",
      description:
        "We maximize the value of data-driven decisions with advanced analytics.",
    },
    {
      title: "Execute Digital Initiatives:",
      description:
        "We plan and execute digital strategies to enhance business visibility and market penetration.",
    },
  ];

  const paraStyle = {
    color: "#7a7e83",
    maxWidth: "100%",
    wordWrap: "breakWord",
  };

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://ottixhow.com/about-us" />
        <title>
          Leading Retail Analytics SaaS Platform for Pricing Analytics
        </title>
        <meta
          name="description"
          content="Explore OttixHow's cutting-edge pricing analytics solutions. Optimize pricing strategies and gain a competitive edge with our robust retail analytics platform."
        />
        <meta
          name="keywords"
          content="Pricing analytics, Pricing Intelligence, retail pricing intelligence"
        />
        <meta
          property="og:title"
          content="Discover OttixHow's Advanced Retail Pricing Intelligence"
        />
        <meta
          property="og:description"
          content="Discover OttixHow's expertise in retail pricing intelligence. Empower your business with advanced analytics and strategic insights for competitive advantage."
        />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "OttixHow",
            "description": "Explore OttixHow's cutting-edge pricing analytics solutions. Optimize pricing strategies and gain a competitive edge with our robust retail analytics platform.",
            "brand": {
              "@type": "Brand",
              "name": "OttixHow"
            },
            "offers": {
              "@type": "Service",
              "url": "https://www.ottixhow.com/about-us"
            }
          }
        `}
        </script>
      </Helmet>

      {videoModal && (
        <VideoModal
          videoModal={videoModal}
          handleCloseModal={handleCloseModal}
          url="https://www.youtube.com/embed/5QHWDng85GA?si=eJdcl8wT16XkQS1N&autoplay=1"
        />
      )}

      <div className={styles.divContainer}>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <h1>About Us</h1>
          <h2>
            Leading Pricing Analytics Platform for Strategic Retail Insights and
            Intelligence
          </h2>
        </div>
      </div>
      <div>
        <Container className="mt-4">
          <p style={paraStyle}>
            Welcome to OttixHow, a premier retail analytics SaaS platform owned
            by Orbio Solutions, designed to revolutionize the way businesses
            approach pricing strategies and customer demand forecasting. As a
            leader in pricing analytics, OttixHow empowers companies with the
            tools and insights they need to excel in today’s competitive retail
            landscape. Our advanced solutions in pricing intelligence and retail
            pricing intelligence are tailored to meet the evolving needs of
            businesses across the globe, providing them with a distinct
            competitive edge.
          </p>
        </Container>
      </div>

      <div>
        <Container>
          <Row>
            <div className="pt-3 pb-1 fs-1 d-flex">
              <h2 style={{ color: "#faa71c" }}>About</h2>
              <h2 style={{ color: "#007fc7", marginLeft: "8px" }}>
                OttixHow: Your Partner in Pricing Analytics
              </h2>
            </div>

            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center order-1 order-md-0"
            >
              <p style={paraStyle}>
                At OttixHow, we understand the critical role that pricing plays
                in the success of any retail business. Pricing is not just about
                numbers; it’s about understanding the market, predicting
                customer behavior, and making informed decisions that drive
                profitability. That’s why we’ve developed a comprehensive retail
                analytics SaaS platform that leverages the latest in machine
                learning and artificial intelligence to deliver precise,
                actionable insights.
              </p>
              <p style={paraStyle}>
                Our platform is designed to analyze your product data in
                real-time, helping you predict customer demands and adjust your
                pricing strategies accordingly. By utilizing OttixHow’s advanced
                pricing analytics, businesses can identify trends, optimize
                pricing, and ultimately enhance their bottom line. Whether you
                are a small retailer or a global enterprise, our platform scales
                to meet your needs, ensuring that you always have access to the
                most relevant and up-to-date information.
              </p>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center order-0 order-md-1"
            >
              <div className={styles.threedee}>
                <Image
                  fluid
                  src="/assets/ottixhowscreen.WebP"
                  alt="products-listing"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container>
          <Row>
            <div className="pt-3 pb-1 fs-1 d-flex">
              <h2 style={{ color: "#faa71c" }}>About</h2>
              <h2 style={{ color: "#007fc7", marginLeft: "8px" }}>
                Orbio Solutions: The Technology Behind OttixHow
              </h2>
            </div>
          </Row>
        </Container>
        <Container>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className={styles.circlesContainer}>
                {/* Outer Circle */}
                <div className={styles.videoplayerOuterCircle}></div>

                {/* Inner Circle */}
                <div className={styles.videoplayerInnerCircle}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundImage: "url(/assets/video-background1.WebP)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  >
                    {/* Outer Circle around the icon */}
                    <div
                      className={styles.waveeffect}
                      onClick={handleOpenVideoModal}
                    >
                      <div>
                        <FaPlay
                          size={30}
                          color="#fff"
                          style={{ marginLeft: "9px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <p style={paraStyle}>
                Orbio Solutions, the parent company of OttixHow, is a technology
                powerhouse based in India with a global client base. Our
                expertise lies in creating scalable, innovative technology
                solutions that are deeply integrated with robust data analytics.
                We pride ourselves on delivering technology that not only meets
                but exceeds our clients’ expectations, helping them to achieve
                their business goals with precision and efficiency.
              </p>
              <p style={paraStyle}>
                At Orbio Solutions, we specialize in a range of technological
                implementations, including:
              </p>
              <p style={paraStyle}>
                Orbio Solutions specializes in Machine Learning & Artificial
                Intelligence, encompassing technological implementations such
                as:
              </p>
              <div className="mt-4">
                <ul style={{ lineHeight: "18px" }}>
                  <li>
                    <h3 className={`${styles.navitemlink} fs-6`}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.orbiosolutions.com"
                      >
                        Digital Transformation & Automation
                      </a>
                    </h3>
                  </li>
                  <li>
                    <h3 className={`${styles.navitemlink} fs-6`}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.orbiosolutions.com"
                      >
                        Cloud Services
                      </a>
                    </h3>
                  </li>
                  <li>
                    <h3 className={`${styles.navitemlink} fs-6`}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.orbiosolutions.com"
                      >
                        Data & AI
                      </a>
                    </h3>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container className="mt-4">
          <Row>
            <Col md={6}>
              <div className={styles.newcard}>
                <Row>
                  <Col
                    md={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <FaRegEye className={styles.cardIcon} />
                  </Col>
                  <Col md={10}>
                    <p style={{ color: "#7a7e83" }}>
                      Our vision is simple: we want to help businesses keep
                      growing by providing top-notch technology services and
                      solutions. We believe that staying ahead with the latest
                      tech helps our clients manage today’s fast-changing
                      business world more easily.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className={styles.newcard}>
                <Row>
                  <Col
                    md={2}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <TbTargetArrow className={styles.cardIcon} />
                  </Col>
                  <Col md={10}>
                    <p style={{ color: "#7a7e83" }}>
                      Our mission is to create and execute digital strategies
                      that are not only effective now but will keep working as
                      your business and the market change. In a world where
                      things are always evolving, we make sure our solutions
                      grow with you and stay useful as your needs change.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col md={12}>
            <h2 className="fs-5">How We Help Businesses Succeed</h2>
            <h4 style={{ color: "#faa61c" }}>
              Understanding your business is key to providing the right
              solutions, and that is where we excel.
            </h4>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4 mb-4">
        <Row>
          <Col lg={6}>
            <img
              src="/assets/helpbusinesses1.WebP"
              alt="helpbusinesses"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
          <Col
            lg={6}
            className="d-flex justify-content-center align-items-center"
          >
            <table className="mt-3 mt-md-0">
              <tbody>
                {helpBusiness.map((item, index) => (
                  <tr key={index} className="d-flex">
                    <td>
                      <div style={{ marginTop: "10px" }}>
                        <TiTick className={styles.tableIcon} />
                      </div>
                    </td>
                    <td className="d-flex">
                      <h5 style={{ fontWeight: "600", fontSize: "16px" }}>
                        {item.title}{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {item.description}
                        </span>
                      </h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
      <FooterImgSection mainTitle={mainTitle} subTitle={subTitle} />
      <AchievementSection />
    </div>
  );
}

export default About;
